import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contact`}</h1>
    <p><strong parentName="p">{`Hours of operation:`}</strong>{` Always`}</p>
    <p><strong parentName="p">{`DesignInquiry`}</strong><br />{`
69 Prospect Street`}<br />{`
Portland, ME 04103`}<br />{`
USA`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:info@designinquiry.net",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`info@designinquiry.net`}</a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/designinquiry/?hl=en",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Instagram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/DesignInquiry/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Facebook`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://designinquiry.substack.com/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Sign up`}</a>{` for our timely, urgent, and sporadic newsletter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      